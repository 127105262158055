import { NavLink, Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { Menu , Transition } from '@headlessui/react';
import { Fragment } from 'react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


function Navbar({ isAuthenticated, user, logout }) {
    const logoutHandler = () => {
        logout()
        //setRedirect(true);
        return (<Navigate to="/login" />)
    };

    const authLinks = (
        <Menu as="div" className="relative inline-block text-left mx-auto sm:m-auto mr-10">
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-full  text-sm font-medium transition ease-in-out duration-150">
              <i class='bx bxs-user-circle text-white text-4xl'></i>
            </Menu.Button>
          </div>
    
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          > 
            <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg drop-shadow-2xl bg-brand-lightgray bg-gray-800 z-10">
              <form>
                <Menu.Item className="hover:bg-gray-900">
                  {({ active }) => (
                    <Link
                      to="/app/profile"
                      className={classNames(
                        active ? 'bg-brand-darkgray text-white' : 'text-white',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Mi perfil
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item className="hover:bg-gray-900">
                  {({ active }) => (
                    <button
                      onClick={logoutHandler}
                      className={classNames(
                        active ? 'bg-brand-darkgray text-white' : 'text-white',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Cerrar sesión
                    </button>
                  )}
                </Menu.Item>
              </form>
            </Menu.Items>
          </Transition>
        </Menu>
      )
    




    if (!isAuthenticated) {
        return(<Navigate to='/login' />)
    }
    return (
        <nav id='navbar' className='bg-gray-900 flex justify-between rounded-lg items-center p-4'>
            <div className='flex items-center gap-2'>
                
                <div id="menu" className="hidden md:inline-flex gap-5 justify-center text-m text-black items-center">
                    {isAuthenticated ? authLinks:
                    <Link to="/login" className="item-menu inline-flex text-black border-2 rounded-lg border-brand-darkgray py-1.5 px-4 bg-brand-darkgray transition duration-300 ease-in-out">Ingresar</Link>}                        
                </div>
                <h2 className='text-white text-xl'>
                    {user ? user.user_name : 'Cargando...'}
                </h2>
            </div>
            <div className='flex gap-4'>
                <NavLink
                    to='/app/launders'
                    className={({ isActive }) =>
                        `w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${isActive ? 'bg-indigo-600' : 'bg-indigo-900 hover:bg-indigo-800'}`
                    }
                >
                    Lavados
                </NavLink>
                <NavLink
                    to='/app/services'
                    className={({ isActive }) =>
                        `w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${isActive ? 'bg-indigo-600' : 'bg-indigo-900 hover:bg-indigo-800'}`
                    }
                >
                    Servicios
                </NavLink>
                <NavLink
                    to='/app/clients'
                    className={({ isActive }) =>
                        `w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${isActive ? 'bg-indigo-600' : 'bg-indigo-900 hover:bg-indigo-800'}`
                    }
                >
                    Clientes
                </NavLink>
                <NavLink
                    to='/app/reports'
                    className={({ isActive }) =>
                        `w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${isActive ? 'bg-indigo-600' : 'bg-indigo-900 hover:bg-indigo-800'}`
                    }
                >
                    Informes
                </NavLink>
            </div>
        </nav>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user
});

export default connect(mapStateToProps, {
    logout
})(Navbar);
