import Layout from "../../hocs/layouts/Layout";
import { delete_client, create_client, edit_client, settle_account } from "../../redux/actions/clients";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Card from "../../components/clients/Card";
import ClientDetail from "../../components/clients/ClientDetail";
import ClientCreate from "../../components/clients/ClientCreate";
import ClientEdit from "../../components/clients/ClientEdit";

function Clients({
    clients,
    delete_client,
    create_client,
    edit_client,
    settle_account
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showEditClient, setShowEditClient] = useState(false);

    const handleSelect = (id) => {
        setSelectedClientId(id);
        setShowForm(false); // Ocultar el formulario cuando se selecciona un cliente
        setShowPopover(false); // Ocultar el popover cuando se selecciona un cliente
        setShowEditClient(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setShowPopover(event.target.value.length > 0); // Mostrar el popover si hay texto de búsqueda
    };

    const handleNewClick = () => {
        setShowForm(true);
        setSelectedClientId(null); // Desseleccionar cualquier cliente seleccionado
        setShowEditClient(false);
    };

    const handleEditClick = () => {
        setShowEditClient(true);
        setShowForm(false); // Ocultar el formulario de creación cuando se edita un cliente
    };

    const handleDeleteSuccess = (dni) => {
        delete_client(dni);
        setSelectedClientId(null); // Deseleccionar el cliente después de eliminarlo
    };

    const filteredData = clients && searchTerm ? clients.filter(client => {
        const normalizedSearchTerm = searchTerm.toLowerCase();
        return (
            client.name.toLowerCase().includes(normalizedSearchTerm) ||
            client.dni.toString().includes(normalizedSearchTerm) ||
            client.email.toLowerCase().includes(normalizedSearchTerm) ||
            client.phone.includes(normalizedSearchTerm)
        );
    }) : [];

    const sortedClients = clients ? [...clients].sort((a, b) => b.id - a.id) : [];

    return (
        <Layout>
            <div className="bg-gray-950 mt-4 flex relative mb-8 gap-4">
                <aside className="text-white rounded-lg w-[600px] relative">
                    <div className="flex mb-1 gap-1">
                        <input 
                            type="search" 
                            id="search" 
                            name="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Buscar cliente"  
                        />
                        <button 
                            onClick={handleNewClick}
                            className="w-36 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                            + Nuevo
                        </button>
                    </div>
                    {showPopover && (
                        <div className="absolute top-12 left-0 w-full bg-gray-800 shadow-lg rounded-lg z-10 max-h-60 overflow-y-auto">
                            <ul>
                                {filteredData.map(client => (
                                    <li 
                                        key={client.id} 
                                        onClick={() => handleSelect(client.id)}
                                        className="p-2 cursor-pointer text-white hover:bg-gray-700"
                                    >
                                        {client.name} - {client.dni} - {client.phone}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <ul className="max-h-[calc(100vh-200px)] overflow-y-auto">
                        {clients && sortedClients.map((client) => (
                            <Card 
                                key={client.id}
                                id={client.id}
                                name={client.name}
                                onSelect={handleSelect}
                                isSelected={selectedClientId === client.id}
                            />
                        ))}
                    </ul>
                </aside>
                <section className="text-white bg-gray-900 rounded-lg w-full relative p-4 h-[calc(100vh-200px)] overflow-y-auto">
                    {showForm && (
                            <ClientCreate
                                submitClient={create_client}
                                setShowForm={setShowForm}
                            />
                    )}
                    {showEditClient && selectedClientId && (
                        <div>
                            {clients && clients.filter(client => client.id === selectedClientId).map(client => (
                                <ClientEdit 
                                    key={client.id}
                                    clientData={client}
                                    submitEdit={edit_client}
                                    setShowEditClient={setShowEditClient}
                                />
                            ))}
                        </div>
                    )}
                    {!showForm && !showEditClient && selectedClientId && (
                        <div>
                            {clients && clients.filter(client => client.id === selectedClientId).map(client => (
                                <ClientDetail 
                                    key={client.id}
                                    name={client.name}
                                    dni={client.dni}
                                    email={client.email}
                                    phone={client.phone}
                                    currentAccountBalance={client.current_account_balance}
                                    delete_client={() => handleDeleteSuccess(client.dni)}
                                    onEdit={handleEditClick}
                                    settle_account={() => settle_account(client.id)}
                                />
                            ))}
                        </div>
                    )}
                </section>
            </div>
        </Layout>
    );
}

const mapStateToProps = state => ({
    clients: state.Clients.clients,
});

export default connect(mapStateToProps, {
    delete_client,
    create_client,
    edit_client,
    settle_account
})(Clients);
