import { Link } from "react-router-dom";
import Logo from "../../assets/img/isotipo-negativo-monocromo.svg";
import { useState } from "react";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <header className="z-20 text-white flex justify-between p-4 bg-blur sticky top-0 items-center bg-gray-950">
        <Link to="/" className="flex gap-2 items-center">
            <img src={Logo} height={40} width={40} alt="Logo" />
            <p className="text-2xl font-semibold">LavaManager</p>
        </Link>

      
        <nav className={`hidden md:flex gap-4 items-center`}>
            <a href="/#features" className="hover:text-indigo-600 transition duration-150">
                Producto
            </a>
            <a href="/#pricing" className="hover:text-indigo-600 transition duration-150">
                Precios
            </a>
            <Link
                to="/login"
                className="bg-gray-900 border-2 border-gray-900 py-2 px-4 rounded-lg hover:bg-gray-800 hover:border-gray-800 transition duration-150"
            >
                Iniciar sesión
            </Link>
            <Link
                to="/payment"
                className="bg-indigo-600 border-2 border-indigo-600 py-2 px-4 rounded-lg hover:bg-indigo-800 hover:border-indigo-800 transition duration-150"
            >
                Probar gratis
            </Link>
        </nav>

        <div className="md:hidden">
            <button
                onClick={toggleMenu}
                aria-expanded={isOpen}
                aria-label="Toggle menu"
                className="text-4xl focus:outline-none"
            >
            {isOpen ? (
                <i className="bx bx-x text-white"></i>
            ) : (
                <i className="bx bx-menu text-white"></i>
            )}
            </button>
        </div>

        {/* Menú desplegable */}
        <div
            className={`absolute top-16 left-0 w-full h-screen bg-gray-950 transition-all duration-300 ease-in-out ${
            isOpen ? "block" : "hidden"
            }`}
        >
            <ul className="flex flex-col gap-4 items-center py-12 px-4">
                <li>
                    <a
                        href="/#features"
                        className="hover:text-indigo-600 transition duration-150"
                        onClick={closeMenu}
                    >
                        Producto
                    </a>
                </li>
                <li>
                    <a
                        href="/#pricing"
                        className="hover:text-indigo-600 transition duration-150"
                        onClick={closeMenu}
                    >
                    Precios
                    </a>
                </li>
                <li className="flex gap-4">
                    <Link
                        to="/login"
                        className="bg-gray-900 border-2 border-gray-900 py-2 px-4 rounded-lg hover:bg-gray-800 hover:border-gray-800 transition duration-150"
                        onClick={closeMenu}
                    >
                        Iniciar sesión
                    </Link>
                    <Link
                        to="/payment"
                        className="bg-indigo-600 border-2 border-indigo-600 py-2 px-4 rounded-lg hover:bg-indigo-800 hover:border-indigo-800 transition duration-150"
                        onClick={closeMenu}
                    >
                        Probar gratis
                    </Link>
                </li>
            </ul>
        </div>
    </header>
  );
}

export default Navbar;
