import { useState } from 'react';


function LaunderCreate({
    clients,
    offered_services,
    currentDate,
    currentTime,
    submitLaunder,
    setShowForm
}) {
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [formData, setFormData] = useState({
        payment_method: '',
        pickup_time: '',
        num_baskets: '',
        payment_total: '',
        order_number: ''
    });

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    };

    const handleServiceChange = (event) => {
        const serviceId = parseInt(event.target.value);
        if (event.target.checked) {
            setSelectedServices([...selectedServices, serviceId]);
        } else {
            setSelectedServices(selectedServices.filter(id => id !== serviceId));
        }
    };

    const onSubmit = e => {
        e.preventDefault();

        const launderData = {
            date: currentDate,
            payment_method: formData.payment_method,
            input_time: currentTime,
            pickup_time: formData.pickup_time,
            num_baskets: formData.num_baskets,
            order_number: formData.order_number,
            payment_total: formData.payment_total,
            id_client: selectedClient,
            offered_services: selectedServices.map(id => ({ id_service: id }))
        };

        submitLaunder(launderData);
        setShowForm(false)
    };

    return (
        <form className="p-4 max-w-[850px] h-[calc(100vh-200px)] overflow-y-auto" onSubmit={onSubmit}>
            <div>
                <h1 className="text-4xl font-bold">Nuevo lavado</h1>
                <div className="my-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-sm font-medium">Cliente</label>
                        <select 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                            value={selectedClient}
                            onChange={handleClientChange}
                        >
                            <option value="">Seleccionar cliente</option>
                            {clients.map(client => (
                                <option key={client.dni} value={client.id}>
                                    {client.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex-1">
                        <label className="block text-sm font-medium">Método de pago</label>
                        <select 
                            name="payment_method"
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                            value={formData.payment_method}
                            onChange={onChange}
                        >
                            <option value="">Seleccionar método</option>
                            <option value="credito">Crédito</option>
                            <option value="debito">Débito</option>
                            <option value="efectivo">Efectivo</option>
                            <option value="transferencia">Transferencia</option>
                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium">Numero de orden:</label>
                    <input 
                        type="text"
                        name="order_number"
                        className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                        value={formData.order_number}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium">Número de canastos:</label>
                    <input 
                        type="number" 
                        name="num_baskets"
                        className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                        value={formData.num_baskets}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium">Hora de recogida (opcional):</label>
                    <input 
                        type="text"
                        name="pickup_time"
                        className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                        value={formData.pickup_time}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium">Pagó por adelantado:</label>
                    <input 
                        type="number"
                        defaultValue={0}
                        name="payment_total"
                        className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                        value={formData.payment_total}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium">Servicios ofrecidos:</label>
                    <div className="flex flex-col ml-4">
                        {offered_services.map(service => (
                            <label key={service.id} className="inline-flex items-center mt-2">
                                <input 
                                    type="checkbox" 
                                    value={service.id}
                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                    onChange={handleServiceChange}
                                />
                                <span className="ml-2">{service.service_name} - {service.price}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <button 
                    type="submit" 
                    className="w-40 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                    Guardar
                </button>
            </div>
        </form>
    );
}

export default LaunderCreate;
