import React from 'react';

function ClientDetail({ id,name, dni, email, phone, currentAccountBalance, delete_client, onEdit, settle_account }) {
    return (
        <div className="ml-8 mt-2 max/h-[calc(100vh-200px)]">
            <div className='flex justify-between'>
                <h1 className="text-4xl font-bold">Detalle del cliente:</h1>
                <div className='flex gap-4 mt-4'>
                    <i onClick={onEdit} class='bx bxs-pencil text-white text-4xl'></i>
                    <i onClick={() => delete_client(dni)} class='bx bxs-trash text-red-600 text-4xl mr-2'></i>
                </div>
            </div>
            <div className="px-6 py-4 ml-6 bg-gray-800 rounded-lg inline-block">
                <p className="text-xl"><span className="font-semibold">Nombre:</span> {name}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">DNI:</span> {dni}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">Email:</span> {email}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">Teléfono:</span> {phone}</p>
            </div>
            <p className='text-xl pl-2 pt-4'><span className='font-bold'>Saldo pendinte:</span> ${currentAccountBalance}</p>

            <button onClick={() => settle_account(id)} className='w-36 flex justify-center mt-6 py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-green-600 hover:bg-green-700'>
                Saldar cuenta
            </button>
        </div>
    );
}

export default ClientDetail;
