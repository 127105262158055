import { useState } from 'react';

function ServiceCreate({ submitService, setShowForm }) {
    const [formData, setFormData] = useState({
        service_name: '',
        price: '',
    });

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        const serviceData = {
            service_name: formData.service_name,
            price: parseFloat(formData.price),
        };

        submitService(serviceData);
        setShowForm(false);
    };

    return (
        <form className="p-4 max-w-[850px] h-[calc(100vh-200px)] overflow-y-auto" onSubmit={onSubmit}>
            <div>
                <h1 className="text-4xl font-bold">Nuevo Servicio</h1>
                <div className="mb-4 mt-2">
                    <label className="block text-sm font-medium">Nombre del servicio:</label>
                    <input 
                        type="text" 
                        name="service_name"
                        className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                        value={formData.service_name}
                        onChange={onChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium">Precio:</label>
                    <input 
                        type="number" 
                        name="price"
                        className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                        value={formData.price}
                        onChange={onChange}
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-40 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                    Guardar
                </button>
            </div>
        </form>
    );
}

export default ServiceCreate;
