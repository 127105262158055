import PaymentForm from "../../components/payment/PaymentForm";
import SignUp from "../../components/signUp/SignUp";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/isotipo-negativo-monocromo.svg";


function Payment () {
    const [signInReady, setSignInReady] = useState(false);

    const handleButton = () => {
        setSignInReady(true);
    }


    return(
        <main className="flex bg-gray-950 min-h-screen">
            <section className="z-10 justify-center items-center min-h-screen bg-gray-950 w-2/5 shadow-payment">
                <header className="p-10">
                    <Link to="/" className="flex gap-1 items-center pl-10">
                        <img                        
                        src={Logo}
                        height={40}
                        width={40}
                        alt="Logo" />
                        <p className="text-white text-3xl font-semibold">LavaManager</p>
                    </Link>
                    <p className="text-gray-300 pt-8 pl-10"><span className={`${signInReady?'text-gray-300':'text-indigo-600'}`}>Tus datos </span>&#10132; <span className={`${signInReady?'text-indigo-600':'text-gray-300'}`}>Metodo de pago</span> </p>
                </header>
                <div className="mx-auto max-w-xl">
                    {signInReady? 
                    <PaymentForm />
                    :
                    <SignUp 
                        handleButton={handleButton} 
                    />}
                </div>
            </section>
            <section className="flex text-white overflow-hidden mt-28 ml-20 px-4">
                <div className="bg-indigo-600 absolute rounded-br-full top-0 left-3/5 h-10 w-2/5 blur-[75px]">
                </div>
                <div className="max-w-xl">
                    <h1 className="z-12 text-white">
                        <span className="font-semibold text-indigo-600 text-4xl">$30298</span>/mes
                    </h1>
                    <p className="mt-4 font-light text-gray-300 text-lg">Incluye acceso total al Sofware de LavaManager <span className="font-semibold">(de forma mensual)</span> con todas sus funcionalidades y actualizaciones recurrentes.</p>
                    <p className="mt-4 font-light text-gray-300 text-lg">Prueba gratuita de 7 días.</p>
                    <p className="mt-4 font-light text-gray-300">Todos los precios en Pesos Argentinos.</p>
                    <div className="flex justify-between text-xl mt-16 text-gray-300">
                        <p>SubTotal:</p>
                        <p className="font-semibold">$30298</p>
                    </div>
                    <div className="flex justify-between text-xl text-gray-300 border-y-[1px] border-gray-800 py-2 mt-2">
                        <p>Impuestos:</p>
                        <p className="font-semibold">$0</p>
                    </div>
                    <div className="flex justify-between font-semibold text-xl mt-2 text-gray-300">
                        <p>Total:</p>
                        <p>$30298</p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Payment;