import PricingCard from "./PricingCard";

function Pricing() {
    const pricings = [
        {
            title: "Prueba gratuita de 7 días",
            price: 0,
            data: [
                "Acceso total a todas las funciones por 7 días sin costo",
                "Oportunidad de experimentar el sistema en acción",
                "¡Comenzá tu prueba gratis hoy!",
            ],
            button: "Probar gratis",
            isImportant: false
        },
        {
            title: "Plan mensual",
            price: "30298",
            data: [
                "Acceso total a todas las funciones por 7 días sin costo",
                "Acceso completo a todas las funcionalidades del sistema",
                "Actualizaciones periódicas de la plataforma",
                "Soporte técnico dedicado",
            ],
            button: "Unite al plan",
            isImportant: true
        }
    ];

    return (
        <section id="pricing" className="text-white mt-16">
            <h2 className="text-4xl md:text-5xl text-center font-semibold mb-8">
                Cómo puede ayudarte Lavamanager
            </h2>
            <article className="block md:flex gap-12 p-4 justify-center">
                {pricings.map((pricing, index) => (
                    <PricingCard
                        key={index}
                        title={pricing.title}
                        price={pricing.price}
                        data={pricing.data}
                        button={pricing.button}
                        isImportant={pricing.isImportant}
                    />
                ))}
            </article>
        </section>
    );
}

export default Pricing;
