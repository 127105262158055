import { connect } from "react-redux";
import { check_authenticated, load_user, refresh } from "../../redux/actions/auth";
import { useEffect, useRef } from "react";
import Navbar from "../../components/navigation/Navbar.jsx";

function Layout({ 
    user, 
    refresh,
    check_authenticated, 
    load_user, 
    children,
}) {
    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            if (user && user.refresh) {
                refresh(user.refresh);
            }
            check_authenticated();
            load_user();
        }
    }, [user, refresh, check_authenticated, load_user]);

    return (
        <main className="bg-gray-950 p-4 min-h-screen">
            <Navbar />
            {children}
        </main>
    );
}

const mapStateToProps = state => ({
    user: state.Auth.user,
});

export default connect(mapStateToProps, {
    check_authenticated,
    load_user,
    refresh
})(Layout);
