import React, { useState } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from "sonner";
import { OneEightyRing } from "react-svg-spinners";
import { Link } from "react-router-dom";



function Profile({
    user,
    clients,
    services
}) {
    const [showPopover, setShowPopover] = useState(false); 
    const [loading, setLoading] = useState(false);

    const profile = {
        username: user.user?.user_name,
        email: user.user?.email,
        address: user.user?.direction,
        city: user.user?.city,
        province: user.user?.province,
        servicesCount: services?.length,
        clientsCount: clients?.length,
        subscriptionStatus: user.user?.has_paid_subscription?"Activa":"Cancelled"
    };

    const handleCancelSubscription = () => {
        setShowPopover(true); 
    };

    const handleConfirmCancel = () => {
        setLoading(true)
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        };

        axios.put(`${process.env.REACT_APP_API_URL}/api/v1/payment/cancel-subscription/`,{}, config)
            .then(response => {
                toast.success("Se cancelo la suscripción correctamente.")
                setLoading(false);
                setShowPopover(false);  
            })
            .catch(error => {
                toast.error("Ocurrió un error inesperado al cancelar la suscripción.");
                setLoading(false);
                setShowPopover(false);

            });
    };

    const handleClosePopover = () => {
        setShowPopover(false); 
    };

    return (
        <main className="bg-gray-950 main-h-screen relative">
            <div className="flex">
                <Link to="/app/launders" className="flex items-center rounded-lg py-2 pl-3 pr-5 m-6 hover:bg-gray-900 transition z">
                    <i class='bx bx-left-arrow-alt text-white text-2xl'></i>
                    <p className="text-white">Volver</p>
                </Link>
            </div>
            <div className="flex justify-center p-14">
                <div className="border-[1px] border-gray-900 rounded-xl p-6 w-[50rem] relative">
                    <div className="flex items-center justify-start">
                        <i className="bx bxs-user-circle text-white text-8xl"></i>
                        <div className="pl-2">
                            <p className="text-white text-xl">{profile.username}</p>
                            <p className="text-gray-400">{profile.email}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 text-white pt-8">
                        <div className="space-y-2">
                            <p className="text-lg">Provincia</p>
                            <p className="text-sm">{profile.province}</p>
                        </div>
                        <div className="space-y-2">
                            <p className="text-lg">Ciudad</p>
                            <p className="text-sm">{profile.city}</p>
                        </div>
                        <div className="space-y-2">
                            <p className="text-lg">Dirección</p>
                            <p className="text-sm">{profile.address}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 text-white pt-6">
                        <div className="space-y-1">
                            <p className="text-lg">Cantidad de Servicios</p>
                            <p className="text-2xl font-bold">{profile.servicesCount}</p>
                        </div>
                        <div className="space-y-1">
                            <p className="text-lg">Cantidad de Clientes</p>
                            <p className="text-2xl font-bold">{profile.clientsCount}</p>
                        </div>
                    </div>
                    <div className="space-y-2 text-white pt-6">
                        <div className="pb-4">
                            <div className="flex gap-2 justify-start items-center">
                                <p className="text-lg">Suscripción:</p>
                                <p className="border-[1px] border-gray-800 rounded-2xl py-1 px-2">{profile.subscriptionStatus}</p>
                            </div>
                            <p className="text-sm">Descripción: Software de LavaManager</p>
                            <p className="text-sm">Tipo: Mensual</p>
                            <p className="text-sm">Monto: $30.298</p>
                        </div>

                        <button 
                            onClick={handleCancelSubscription} 
                            className="bg-red-800 hover:bg-red-700 py-2 px-4 rounded-lg"
                        >
                            Cancelar Suscripción
                        </button>

                        {showPopover && (
                            <>
                                {/* Fondo con opacidad */}
                                <div className="fixed inset-0 bg-black bg-opacity-50"></div>

                                {/* Popover con animación */}
                                <div 
                                    className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-950 border-[1px] border-gray-900 p-6 rounded-lg shadow-lg transition-all duration-500 ease-in-out ${
                                        showPopover ? "opacity-100 scale-100" : "opacity-0 scale-50"
                                    }`}
                                >
                                    <p className="text-white text-lg mb-4">¿Estás seguro de que quieres cancelar la suscripción?</p>
                                    <p className="text-gray-400">Al cancelar la suscripción no tendrás mas acceso a las funcionalidades de LavaManager a partir de cuando se venza tu plazo de suscripción.</p>
                                    <div className="flex gap-4 justify-end">
                                        <button 
                                            onClick={handleClosePopover} 
                                            className="bg-gray-900 hover:bg-gray-800 text-white py-2 px-4 rounded-lg"
                                        >
                                            Cancelar
                                        </button>
                                        <button 
                                            onClick={handleConfirmCancel} 
                                            className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded-lg"
                                        >
                                            {loading? <OneEightyRing 
                                                width={23}
                                                height={23}
                                                color="#d0cfcd" />: <p>Confirmar</p>}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}

const mapStateToProps = state => ({
    user: state.Auth,
    clients: state.Clients.clients,
    services: state.Services.services,
});

export default connect(mapStateToProps, {

})(Profile);
