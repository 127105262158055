import { Link, Navigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { reset_password_confirm } from "../../redux/actions/auth";
import { useEffect, useState } from "react";
import { OneEightyRing } from "react-svg-spinners";
import Logo from "../../assets/img/isotipo-negativo-monocromo.svg";


function ResetPassword({ reset_password_confirm, loading, isAuthenticated }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const params = useParams();

    const [requestSent, setRequestSent] = useState(false);

    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: '',

      })
    
    const {
        new_password,
        re_new_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e =>{
        e.preventDefault();
        const uid = params.uid
        const token = params.token

        reset_password_confirm(uid, token, new_password, re_new_password)
        if (new_password === re_new_password) {
            setRequestSent(true);
        };

      }
      
    if (requestSent && !loading)
      return <Navigate to='/login' />;

    return (
        <div className="min-h-screen flex items-center justify-center w-full bg-gray-950">
            <div className="bg-gray-900 shadow-md rounded-lg px-8 py-6 max-w-md">
                <Link to="/" className="flex justify-center gap-1 items-center pb-3">
                    <img
                    src={Logo}
                    height={40}
                    width={40}
                    alt="Logo" />
                    <p className="text-white text-3xl font-semibold">LavaManager</p>
                </Link>
                <form onSubmit={onSubmit} method="POST" className="w-80">
                    <p className="text-gray-300 text-sm pb-4">Ingresa la nueva contraseña</p>
                    <div className="mb-4">
                    <label htmlFor="new_password" className="block text-sm font-medium text-gray-300 mb-2">Contraseña</label>
                        <input 
                            type="password" 
                            id="new_password" 
                            name="new_password" 
                            value={new_password} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Ingresa tu contraseña" 
                            required 
                        />
                    </div>
                    <div className="mb-4">
                    <label htmlFor="re_new_password" className="block text-sm font-medium text-gray-300 mb-2">Nueva Contraseña</label>
                        <input 
                            type="password" 
                            id="re_new_password" 
                            name="re_new_password" 
                            value={re_new_password} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Ingresa tu contraseña" 
                            required 
                        />
                    </div>
                    
                    {loading ?  
                    <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <OneEightyRing 
                        width={25}
                        height={25}
                        color="#fff" 
                    />
                    </button>
                    :<button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Iniciar sesión</button>
                    }
                    
                </form>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    isAuthenticated: state.Auth.isAuthenticated
});

export default connect(mapStateToProps, { reset_password_confirm })(ResetPassword);