import { Link } from "react-router-dom";
import Logo from "../../assets/img/isotipo-negativo-monocromo.svg"


function Footer() {
    return (
        <footer className="block md:flex justify-between text-white mx-4 md:mx-52 items-center py-20 md:py-36">
            <div className="">
                <Link to={"/"} className="flex gap-1 items-center mb-8">
                    <img
                    src={Logo}
                    height={40}
                    width={40}
                    alt="Logo" />
                    <p className="text-3xl font-semibold">LavaManager</p>
                </Link>
                <Link to="/payment" className="bg-indigo-600 border-2 border-indigo-600 py-3 px-4 rounded-lg hover:bg-indigo-800 hover:border-indigo-800 transition duration-150">
                    Probar gratis
                </Link>
            </div>
            <div className="mt-12 md:mt-0">
                <div className="z-10">
                    <a href="/#features" className="block hover:text-indigo-600 transition duration-150 mb-1">Producto</a>
                    <a href="/#pricing" className="block hover:text-indigo-600 transition duration-150 mb-1">Precios</a>
                    <a href="/" className="block hover:text-indigo-600 transition duration-150">Sobre nosotros</a>
                </div>
            <div className="z-0 bg-indigo-600 absolute rounded-tl-full button-0 right-0 h-16 w-3/5 blur-[100px]">
            </div>
            </div>
        </footer>
    )
}

export default Footer;