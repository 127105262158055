import Layout from "../../hocs/layouts/Layout";
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { create_launder, delete_launder, mark_as_finish, mark_as_paid } from "../../redux/actions/launders";
import Card from "../../components/launders/Card";
import LaunderDetail from "../../components/launders/LaunderDetail.";
import LaunderCreate from "../../components/launders/LaunderCreate";
import { get_launders } from "../../redux/actions/launders";
import { get_clients } from '../../redux/actions/clients';
import { get_services } from '../../redux/actions/services';

function Launders({
    mark_as_paid,
    mark_as_finish,
    delete_launder,
    launders,
    clients,
    services,
    create_launder,
    get_launders,
    get_clients,
    get_services
}) {
    useEffect(() => {
        get_launders();
        get_clients();
        get_services();
    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLaunderId, setSelectedLaunderId] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const handleSelect = (id) => {
        setSelectedLaunderId(id);
        setShowForm(false); // Ocultar el formulario cuando se selecciona un item
        setShowPopover(false); // Ocultar el popover cuando se selecciona un item
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setShowPopover(event.target.value.length > 0); // Mostrar el popover si hay texto de búsqueda
    };

    const handleNewClick = () => {
        setShowForm(true);
        setSelectedLaunderId(null); // Desseleccionar cualquier launder seleccionado
    };

    const handleDeleteSuccess = (id) => {
        delete_launder(id);
        setSelectedLaunderId(null); // Deseleccionar el launder después de eliminarlo
    };

    const filteredData = launders && searchTerm ? launders.filter(item => {
        const normalizedSearchTerm = searchTerm.toLowerCase();

        return (
            item &&
            (item.client_name.toLowerCase().includes(normalizedSearchTerm) ||
            item.client_dni.toString().includes(normalizedSearchTerm) ||
            item.client_email.toLowerCase().includes(normalizedSearchTerm) ||
            item.client_phone.includes(normalizedSearchTerm))
        );
    }) : [];

    const sortedLaunders = launders ? [...launders].sort((a, b) => b.id - a.id) : [];   
    
    const currentDate = new Date().toISOString().split('T')[0];
    const currentTime = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    
    return (
        <Layout>
            <div className=" bg-gray-950 mt-4 flex relative mb-8 gap-4">
                <aside className="text-white rounded-lg w-[600px] relative">
                    <div className="flex mb-1 gap-1">
                        <input 
                            type="search" 
                            id="search" 
                            name="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Buscar lavado"  
                        />
                        <button 
                            onClick={handleNewClick}
                            className="w-36 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                            + Nuevo
                        </button>
                    </div>
                    {showPopover && (
                        <div className="absolute top-12 left-0 w-full bg-gray-800 shadow-lg rounded-lg z-10 max-h-60 overflow-y-auto">
                            <ul>
                                {filteredData.map(item => (
                                    <li 
                                        key={item.id} 
                                        onClick={() => handleSelect(item.id)}
                                        className="p-2 cursor-pointer text-white hover:bg-gray-700"
                                    >
                                        {item.client_name} - {item.client_dni} - {item.client_phone}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <ul className="max-h-[calc(100vh-200px)] overflow-y-auto">
                        {launders && sortedLaunders.map((item) => (
                            <Card 
                                key={item.id}
                                id={item.id}
                                status={item.status}
                                client_name={item.client_name}
                                offered_services={item.offered_services}
                                payment_total={item.payment_total}
                                total_price={item.total_price}
                                date={item.date}
                                order_number={item.order_number}
                                onSelect={handleSelect}
                                isSelected={selectedLaunderId === item.id}
                            />
                        ))}
                    </ul>
                </aside>
                <section className="text-white bg-gray-900 rounded-lg w-full relative p-4 h-[calc(100vh-150px)] overflow-y-auto">
                    {showForm && (
                        <LaunderCreate
                            clients={clients}
                            offered_services={services}
                            currentDate={currentDate}
                            currentTime={currentTime}
                            submitLaunder={create_launder}
                            setShowForm={setShowForm}
                        />
                    )}
                    {!showForm && selectedLaunderId && (
                        <div>
                            {launders && launders.filter(item => item.id === selectedLaunderId).map(item => (
                                <LaunderDetail
                                    id={item.id}
                                    status={item.status}
                                    client_name={item.client_name}
                                    client_email={item.client_email}
                                    client_phone={item.client_phone}
                                    client_dni={item.client_dni}
                                    total_price={item.total_price}
                                    payment_total={item.payment_total}
                                    offered_services={item.offered_services}
                                    pickup_time={item.pickup_time}
                                    input_time={item.input_time}
                                    num_baskets={item.num_baskets}
                                    order_number={item.order_number}
                                    mark_as_paid={mark_as_paid}
                                    mark_as_finish={mark_as_finish}
                                    delete_launder={() => handleDeleteSuccess(item.id)}
                                />
                            ))}
                        </div>
                    )}
                </section>
            </div>
        </Layout>
    );
}

const mapStateToProps = state => ({
    launders: state.Launders.launders,
    clients: state.Clients.clients,
    services: state.Services.services,
});

export default connect(mapStateToProps, {
    mark_as_paid,
    mark_as_finish,
    delete_launder,
    create_launder,
    get_launders,
    get_clients,
    get_services
})(Launders);
