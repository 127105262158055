import {
    GET_LAUNDERS_SUCCESS,
    GET_LAUNDERS_FAIL,
    MARK_AS_PAID_SUCCESS,
    MARK_AS_PAID_FAIL,
    DELETE_LAUNDER_SUCCESS,
    DELETE_LAUNDER_FAIL,
    MARK_AS_FINISH_SUCCESS,
    MARK_AS_FINISH_FAIL,
    CREATE_LAUNDER_SUCCESS,
    CREATE_LAUNDER_FAIL,
} from '../actions/types';

const initialState = {
    launders: null
};

export default function Launders(state= initialState, action) { 
    const { type, payload } = action

    switch(type) {
        case GET_LAUNDERS_SUCCESS:
            return {
                ...state,
                launders: payload
            }

        case GET_LAUNDERS_FAIL:
            return{
                ...state,
                launders: null
            }

        case DELETE_LAUNDER_SUCCESS:
            return {
                ...state,
                launders: state.launders.filter(launder => launder.id !== payload)
            }

        case DELETE_LAUNDER_FAIL:
            return state;

        case CREATE_LAUNDER_SUCCESS:
            return {
                ...state,
                launders: [...state.launders, payload]
            }
    
        case CREATE_LAUNDER_FAIL:
            return state;
        case MARK_AS_FINISH_SUCCESS:
            return {
                ...state,
                launders: state.launders.map(launder => launder.id === payload.id ? payload : launder)
            }
        case MARK_AS_FINISH_FAIL:
            return state;

        case MARK_AS_PAID_SUCCESS:
            return {
                ...state,
                launders: state.launders.map(launder => launder.id === payload.id ? payload : launder)
            }
        case MARK_AS_PAID_FAIL:
            return state;
        default:
            return state;
    }
}