import { combineReducers } from "redux";
import Auth from "./auth";
import Launders from "./launders";
import Clients from "./clients";
import Services from "./services";

export default combineReducers({
    Auth,
    Launders,
    Clients,
    Services
})