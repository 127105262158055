import {
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    LOGIN_SUCCES,
    LOGIN_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    LOGOUT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_CONFIRM_SUCCESS,
    RESET_PASSWORD_CONFIRM_FAIL,
} from './types';
import axios from 'axios';
import { toast } from 'sonner';


export const check_authenticated = () => async dispatch => {
    if(localStorage.getItem('access')){
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/verify/`, config)

            if (res.status === 200) {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        }
        catch(err){
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }
    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
}

export const signup = (user_name, email, password, re_password, province, city, direction) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    const body = JSON.stringify({
        user_name, 
        email, 
        password, 
        re_password, 
        province, 
        city,
        direction
    });

    if (password !== re_password){
        dispatch({
            type: SIGNUP_FAIL,
        })
        toast.error("Las contraseñas no coinciden.")} else {

    try {
        //const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register/`, body, config);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/register/`, body, config);
        if (res.status === 201) {
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: res.data
            });
            dispatch(login(email, password));
            toast.success(res.data.message);
            //login(email, password)
        } else{
            dispatch({
                type: SIGNUP_FAIL
            });
            toast.error(res.data.error);
        }   
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
    }
    catch (err) {
        
        dispatch({
            type: SIGNUP_FAIL
        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
        toast.error("Ocurrio un error inesperado, intentalo de nuevo o mas tarde.");
    }
}
};

export const load_user = () => async dispatch => {
    if(localStorage.getItem('access')){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/profile/`, config);

            if (res.status === 200) {
                dispatch({
                    type: USER_LOADED_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }
        }
        catch(err){
            dispatch({
                type: USER_LOADED_FAIL
            }); 
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
}

export const login = (email, password) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    const body = JSON.stringify({
        email,
        password
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/login/`, body, config);

        if (res.status === 200) {
            dispatch({
                type: LOGIN_SUCCES,
                payload: res.data
            });
            dispatch(load_user());
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            toast.success(res.data.message)
        } else {
            dispatch({
                type: LOGIN_FAIL
            });
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            toast.error(res.data.error);
        }
    } catch(err) {
        dispatch({
            type: LOGIN_FAIL
        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
        toast.error("Error al iniciar sesión")
    }
}


export const refresh = (refresh) => async dispatch => {
    if (localStorage.getItem('refresh')) {
        const body = JSON.stringify({
            refresh: refresh
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/token/refresh/`, body);
            if (res.status === 200) {
                dispatch({
                    type: REFRESH_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: REFRESH_FAIL
                });
            }
        }
        catch (err){
            dispatch({
                type: REFRESH_FAIL
            });
        }
    } else {
        dispatch({
            type: REFRESH_FAIL
        });
    }
}

export const reset_password = (email) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    const body = JSON.stringify({ email });

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/send_reset_password_email/`, body, config);

        if(res.status === 200) {
            dispatch({
                type: RESET_PASSWORD_SUCCESS
            });
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            toast("Se envió el mail para cambiar la contraseña.");
        } else {
            dispatch({
                type: RESET_PASSWORD_FAIL
            });
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            toast("Error al enviar el mail para cambiar la contraseña."); 
        }
    }
    catch(err){
        dispatch({
            type: RESET_PASSWORD_FAIL
        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
        toast.error("Ocurrio un error inesperado, intentalo de nuevo o mas tarde.");
    }
}

export const reset_password_confirm = (uid, token, password, re_password) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    const body = JSON.stringify({
        password,
        re_password
    });

    if (password !== re_password) {
        dispatch({
            type: RESET_PASSWORD_CONFIRM_FAIL
        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
        toast.error("Las contraseñas no coinciden.")
    } else {
        try{
            //const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/reset_password/${uid}/${token}/`, body, config);
            if (res.status === 200) {
                dispatch({
                    type: RESET_PASSWORD_CONFIRM_SUCCESS
                });
                dispatch({
                    type: REMOVE_AUTH_LOADING
                });
                toast.success("La contraseña se restableció correctamente.")
            } else {
                dispatch({
                    type: RESET_PASSWORD_CONFIRM_FAIL
                });
                dispatch({
                    type: REMOVE_AUTH_LOADING
                });
                toast.error("Error al restablecer tu contraseña");
            }
        }
        catch(err){
            dispatch({
                type: RESET_PASSWORD_CONFIRM_FAIL
            });
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            toast.error("Error al restablecer tu contraseña");
        }
        }
    }

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
    toast.error("Sesión cerrada con éxito");
}