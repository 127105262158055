import Layout from "../../hocs/layouts/Layout";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { create_service, delete_service, edit_service } from "../../redux/actions/services";
import Card from "../../components/services/Card";
import ServiceDetail from "../../components/services/ServiceDetail";
import ServiceCreate from "../../components/services/ServiceCreate";
import ServiceEdit from "../../components/services/ServiceEdit";


function Services({
    services,
    delete_service,
    create_service,
    edit_service,
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showEditService, setShowEditService] = useState(false);

    const handleSelect = (id) => {
        setSelectedServiceId(id);
        setShowForm(false); // Ocultar el formulario cuando se selecciona un servicio
        setShowPopover(false); // Ocultar el popover cuando se selecciona un servicio
        setShowEditService(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setShowPopover(event.target.value.length > 0); // Mostrar el popover si hay texto de búsqueda
    };

    const handleNewClick = () => {
        setShowForm(true);
        setSelectedServiceId(null); // Desseleccionar cualquier servicio seleccionado
        setShowEditService(false);
    };

    const handleEditClick = () => {
        setShowEditService(true);
        setShowForm(false); // Ocultar el formulario de creación cuando se edita un servicio
    };

    const filteredData = services && searchTerm ? services.filter(service => {
        const normalizedSearchTerm = searchTerm.toLowerCase();

        return (
            service.service_name.toLowerCase().includes(normalizedSearchTerm) ||
            service.price.toString().includes(normalizedSearchTerm)
        );
    }) : [];

    const sortedServices = services ? [...services].sort((a, b) => b.id - a.id) : [];

    return (
        <Layout>
            <div className=" bg-gray-950 mt-4 flex relative mb-8 gap-4">
                <aside className="text-white rounded-lg w-[600px] relative">
                    <div className="flex mb-1 gap-1">
                        <input 
                            type="search" 
                            id="search" 
                            name="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Buscar servicio"  
                        />
                        <button 
                            onClick={handleNewClick}
                            className="w-36 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                            + Nuevo
                        </button>
                    </div>
                    {showPopover && (
                        <div className="absolute top-12 left-0 w-full bg-gray-800 shadow-lg rounded-lg z-10 max-h-60 overflow-y-auto">
                            <ul>
                                {filteredData.map(service => (
                                    <li 
                                        key={service.id} 
                                        onClick={() => handleSelect(service.id)}
                                        className="p-2 cursor-pointer text-white hover:bg-gray-700"
                                    >
                                        {service.service_name} -${service.price}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <ul className="max-h-[calc(100vh-200px)] overflow-y-auto">
                        {services && sortedServices.map((service, index) => (
                            <Card 
                                key={service.id}
                                id={service.id}
                                service_name={service.service_name}
                                price={service.price}
                                onSelect={handleSelect}
                                isSelected={selectedServiceId === service.id}
                            />
                        ))}
                    </ul>
                </aside>
                <section className="text-white bg-gray-900 rounded-lg w-full relative p-4 h-[calc(100vh-200px)] overflow-y-auto">
                    {showForm && (
                        <div>
                            <ServiceCreate
                                submitService={create_service}
                                setShowForm={setShowForm}
                            />
                        </div>
                    )}
                    {showEditService && selectedServiceId && (
                        <div>
                            {services && services.filter(item => item.id === selectedServiceId).map(item => (
                                <ServiceEdit 
                                    key={item.id}
                                    serviceData={item}
                                    submitEdit={edit_service}
                                    setShowEditService={setShowEditService}

                                />
                            ))}
                        </div>
                    )}
                    {!showForm && !showEditService && selectedServiceId && (
                        <div>
                            {services && services.filter(item => item.id === selectedServiceId).map(item => (
                                <ServiceDetail 
                                    key={item.id}
                                    service_name={item.service_name}
                                    price={item.price}
                                    delete_service={() => delete_service(item.service_name)}
                                    onEdit={handleEditClick}
                                />
                            ))}
                        </div>
                    )}
                </section>
            </div>
        </Layout>
    );
}

const mapStateToProps = state => ({
    services: state.Services.services,
});

export default connect(mapStateToProps, {
    delete_service,
    create_service,
    edit_service
})(Services);
