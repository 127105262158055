import {
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAIL,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAIL,
    EDIT_SERVICE_SUCCESS,
    EDIT_SERVICE_FAIL,
} from './types';
import axios from 'axios';
import { toast } from 'sonner';


export const get_services = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/services/list-services/`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_SERVICES_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_SERVICES_FAIL
            });
            toast.error("Ocurrió un error inesperado al cargar los servicios.");
        }
    }
    catch(err) {
        dispatch({
            type: GET_SERVICES_FAIL
        });
        toast.error("Ocurrió un error inesperado al cargar los servicios.");
    }
};


export const delete_service = (service_name) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/services/delete-service/${service_name}/`, config);

        if(res.status === 200) {
            dispatch({
                type: DELETE_SERVICE_SUCCESS,
                payload: service_name
            });
            toast.success(res.data.success)
        } else {
            dispatch({
                type: DELETE_SERVICE_FAIL
            });
            toast.error("Ocurrió un error inesperado al borrar el servicio.");
        };
    } catch(err){
        dispatch({
            type: DELETE_SERVICE_FAIL
        });
        toast.error("Ocurrió un error inesperado al borrar el servicio.");
    };
};


export const create_service = (serviceData) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/services/create-service/`, serviceData, config);

        if(res.status === 201) {
            dispatch({
                type: CREATE_SERVICE_SUCCESS,
                payload: res.data.service
            });
            toast.success(res.data.success);
        } else {
            dispatch({
                type: CREATE_SERVICE_FAIL
            });
            toast.error("Ocurrió un error inesperado al crear el sevicio.");
        }
    } catch(err) {
        dispatch({
            type: CREATE_SERVICE_FAIL
        });
        toast.error("Ocurrió un error inesperado al crear el sevicio.");
    };

};


export const edit_service = (serviceData) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/services/update-service/`, serviceData, config);

        if(res.status === 200) {
            dispatch({
                type: EDIT_SERVICE_SUCCESS,
                payload: res.data.service
            });
            toast.success(res.data.success);
        } else {
            dispatch({
                type: EDIT_SERVICE_FAIL
            });
            toast.error("Ocurrió un error inesperado al actualizar el servicio.");
        }
    } catch(err){
        dispatch({
            type: EDIT_SERVICE_FAIL
        });
        toast.error("Ocurrió un error inesperado al actualizar el servicio.");
    };
};